import Modal from "~/components/modal";
import { RemixForm } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Errors } from "~/components/remix-form/errors";
import { Input } from "~/components/remix-form/input";
import type { CommunityQuery, EditCommunityQuery } from "~/types/api";
import { useFetcherData } from "~/utils/remix";

interface Props {
  id?: string;
  onClose(result?: EditCommunityQuery["community"]): void;
  source?: EditCommunityQuery["community"];
}

export default function CommunityForm({ id, ...props }: Props) {
  const fetcher = useFetcherData<CommunityQuery>(
    id ? `/resources/communities/${id}/edit` : undefined
  );
  if (id && !fetcher.data) {
    return null;
  }
  return <Form source={id ? fetcher.data?.community : undefined} {...props} />;
}

const Form = ({ onClose, source }: Props) => {
  return (
    <Modal onExplicitClose={onClose}>
      <RemixForm
        data={source}
        fetcher
        action="/resources/communities/save"
        onSuccess={onClose}
      >
        <Modal.Header title="Add a Community" />
        <Modal.Body>
          <Errors />
          <Input name="name" autoFocus />
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-2">
              <Input name="city" />
            </div>
            <Input name="state" type="state" />
          </div>
          <Input name="description" type="textarea" />
          {source && (
            <Input
              name="complete"
              type="checkbox"
              inline
              label="100% of this community's addresses are listed"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal />
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};
