import * as Sentry from "@sentry/react";
import clsx from "clsx";
import _ from "lodash";
import { useState } from "react";
import { Editor, Element, Node, Path, Range, Transforms } from "slate";
import type { RenderElementProps } from "slate-react";
import { ReactEditor, useSlate } from "slate-react";
import Button from "~/components/button";
import ButtonLink from "~/components/button-link";
import { IconExternalLink, IconLink } from "~/components/icons";
import type { ModalProps } from "~/components/modal";
import Modal from "~/components/modal";
import { RemixForm } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Input } from "~/components/remix-form/input";
import type {
  CustomElement,
  LinkElement,
  MyEditor
} from "~/components/rich-editor";

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (node) => Element.isElement(node) && node.type === "link"
  });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElement(node) && node.type === "link"
  });
};

const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const link: LinkElement = { type: "link", url, children: [] };
  Transforms.wrapNodes(editor, link, { split: true });
  Transforms.collapse(editor, { edge: "end" });
};

export const LinkButton = () => {
  const editor = useSlate() as MyEditor;
  return (
    <span
      className={clsx(editor.selection && "cursor-pointer", "mr-2")}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("url");
        if (!url) return;
        editor.insertLink(url);
      }}
    >
      <IconLink fixed />
    </span>
  );
};

export const Link = (props: RenderElementProps) => {
  const { attributes, children } = props;
  const element = props.element as LinkElement;
  const [show, setShow] = useState(false);
  const editor = useSlate() as MyEditor;
  const path = ReactEditor.findPath(editor, element);

  return (
    <a
      {...attributes}
      href={element.url}
      onClick={
        editor.readOnly
          ? undefined
          : (e) => {
              // Don't follow the link when we're editing
              e.preventDefault();
              setShow(true);
            }
      }
    >
      {children}
      {show && (
        <EditLink editor={editor} path={path} onClose={() => setShow(false)} />
      )}
    </a>
  );
};

type EditLinkProps = ModalProps & {
  editor: MyEditor;
  path: number[];
};

const EditLink = ({ editor, path, onClose }: EditLinkProps) => {
  const element = Editor.node(editor, path)[0] as LinkElement;
  const [url, setUrl] = useState(element.url || "");

  const remove = () => {
    Transforms.unwrapNodes(editor, { at: path });
  };

  const save = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Transforms.setNodes(editor, { url }, { at: path });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <RemixForm onSubmit={save}>
        <Modal.Header title="Edit Link" />
        <Modal.Body>
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <Input
                name="url"
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="pt-3.5">
              {url && (
                <ButtonLink to={url} target="_blank">
                  <IconExternalLink /> Open Link
                </ButtonLink>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Buttons modal disabled={!url.trim()}>
            <Button mode="danger" onClick={remove}>
              Remove
            </Button>
          </Buttons>
        </Modal.Footer>
      </RemixForm>
    </Modal>
  );
};

export interface EditorWithLinks extends ReactEditor {
  insertLink: (url: string) => void;
}

export const withLinks = (editor: MyEditor) => {
  const e = editor as MyEditor;
  const { isInline, insertText, insertBreak } = editor;

  e.isInline = (element) =>
    element.type === "link" ? true : isInline(element);

  e.insertLink = (url) => {
    // Automatically prepend intranet or protocol
    let newUrl = url;
    if (newUrl.startsWith("/")) {
      newUrl = `https://intranet.petefowler.com${url}`;
    } else if (!url.match(/^\w+:\/\//)) {
      newUrl = `http://${url}`;
    }

    if (e.selection && Range.isExpanded(e.selection)) {
      wrapLink(e, newUrl);
      return;
    }

    const text: string = window.prompt("text for link", "text") || newUrl;

    Transforms.insertNodes(e, {
      type: "link",
      url: newUrl,
      children: [{ text }]
    });
  };

  const linkify = () => {
    if (editor.selection && Range.isCollapsed(editor.selection)) {
      const path = editor.selection!.anchor.path;
      const entry = Editor.parent(editor, path);
      const parent = entry[0] as CustomElement;
      if (editor.isInline(parent)) {
        return;
      }
      let node = Node.leaf(editor, path);
      // const z = Editor.parent(editor, editor.selection!.anchor.path);
      const re =
        /\b((?:[A-Z0-9._%+-]+@)?(?:pfcs\.co|www\.|[A-Za-z0-9-.]+\.(?:com|net|org)|\w+:\/\/)(?:[.)](?![\s.)]|$)|[^\s.)])*)/i;
      const emailRe =
        /\b(([A-Z0-9._%+-]+@)(?:pfcs\.co|www\.|[A-Za-z0-9-.]+\.(?:com|net|org)|\w+:\/\/)(?:[.)](?![\s.)]|$)|[^\s.)])*)/i;
      if (node.text.match(re)) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.splitNodes(editor, { always: true });
          node = Node.leaf(editor, path);
          const parts = node.text.split(re).filter(Boolean).reverse();

          for (const part of parts) {
            if (part.match(re)) {
              let url = part;
              if (url.match(emailRe)) {
                url = `mailto:${url}`;
              } else if (!url.match(/^\w+:\/\//)) {
                url =
                  url.startsWith("pfcs.co") || url.startsWith("petefowler.com")
                    ? `https://${url}`
                    : `http://${url}`;
              }
              Transforms.insertNodes(
                editor,
                [
                  {
                    type: "link",
                    url,
                    children: [{ ...node, text: part }]
                  }
                ],
                { at: path }
              );
            } else {
              Transforms.insertNodes(editor, [{ ...node, text: part }], {
                at: path
              });
            }
          }
          Transforms.removeNodes(editor, {
            at: [...Path.parent(path), _.last(path)! + parts.length]
          });
          Transforms.mergeNodes(editor, { at: Path.next(Path.parent(path)) });
        });
      }
    }
  };
  e.insertBreak = () => {
    linkify();
    insertBreak();
  };
  e.insertText = (text: string) => {
    Sentry.addBreadcrumb({
      category: "paste",
      message: "link insert text",
      data: {
        editor: JSON.stringify(editor.children),
        path: JSON.stringify(editor.selection),
        text
      }
    });
    insertText(text);
    if (text === " ") {
      linkify();
    }
  };

  return e;
};
