import _ from "lodash";
import type {
  AddressFieldsFragment,
  BpaLinkFieldsFragment,
  BpaLinkInput,
  IssueFieldsFragment,
  ListItemFieldsFragment,
  LocationFieldsFragment,
  ProjectFrameFieldsFragment
} from "~/types/api";
import DisplayNames from "~/utils/display-names";
import { normalizedNumber } from "~/utils/formatting";

export type Flattened<T> = T & { indent: number; parentPath?: string[] };

export const parseBPALinks = (source: {
  id: string;
  bpaLinks: BpaLinkFieldsFragment[];
}) => {
  return source.bpaLinks.map((l) =>
    _.omit(
      {
        ...l,
        descriptionSlate: l.descriptionSlate
          ? JSON.parse(l.descriptionSlate)
          : null
      },
      "__typename"
    )
  );
};

export const stringifyBPALinks = (data: {
  bpaLinks?: BpaLinkInput[] | null;
}) => {
  if (!data.bpaLinks) {
    return data.bpaLinks;
  }
  return data.bpaLinks.map((l) => ({
    ...l,
    descriptionSlate: l.descriptionSlate
      ? JSON.stringify(l.descriptionSlate)
      : l.descriptionSlate
  }));
};

type Processor<T> = (
  group: T[],
  indent?: number,
  parent?: Flattened<T>
) => Flattened<T>[];

export const flattenAddresses = (
  addresses: AddressFieldsFragment[],
  project?: ProjectFrameFieldsFragment,
  sort = "name"
) => {
  if (!addresses) return [];

  const processItems: Processor<AddressFieldsFragment> = (
    group,
    indent = 0
  ) => {
    return _.flatten(
      group.map((item) => [
        { ...item, indent },
        ...processItems(
          _.sortBy(
            addresses.filter((a) => a.address?.id === item.id),
            sort === "name"
              ? (a) => normalizedNumber(DisplayNames.address(a))
              : sort
          ),
          indent + 1
        )
      ])
    );
  };

  console.log({ sort });
  return processItems(
    _.sortBy(
      addresses.filter((a) => !a.address),
      sort === "name"
        ? (a) => normalizedNumber(DisplayNames.address(a))
        : sort.startsWith("customFieldsMap.")
          ? (a) => {
              const name = sort.replace("customFieldsMap.", "");
              const value = (
                JSON.parse(a.customFields) as Record<string, string>
              )[name];
              const field = project?.addressFields.find((f) => f[0] === name);
              console.log({ name, value, field });
              if (field) {
                return (
                  (field[1] === "Accounting"
                    ? Number.parseFloat(value?.replace(/[^\d.]/g, ""))
                    : value?.trim()) || "zzz"
                );
              }
              return value;
            }
          : sort
    )
  );
};

export const flattenListItems = (items: ListItemFieldsFragment[]) => {
  if (!items) return [];
  const processItems: Processor<ListItemFieldsFragment> = (
    group,
    indent = 0
  ) => {
    return _.flatten(
      group.map((item) => [
        { ...item, indent },
        ...processItems(
          items.filter((i) => i.item?.id === item.id),
          indent + 1
        )
      ])
    );
  };

  return processItems(items.filter((i) => !i.item));
};

export const flattenIssues = (issues: IssueFieldsFragment[]) => {
  if (!issues) return [];

  const processItems: Processor<IssueFieldsFragment> = (
    group,
    indent = 0,
    parent = undefined
  ) => {
    return _.flatten(
      group.map((item) => {
        const next = {
          ...item,
          indent,
          parentPath: parent ? [...(parent.parentPath || []), parent.id] : []
        };
        return [
          next,
          ...processItems(
            _.sortBy(
              issues.filter((i) => i.issue?.id === item.id),
              (i) => normalizedNumber(i.number),
              "alternateNumber",
              "description"
            ),
            indent + 1,
            next
          )
        ];
      })
    );
  };

  return processItems(
    _.sortBy(
      issues.filter((i) => !i.issue),
      (i) => normalizedNumber(i.number),
      "alternateNumber",
      "description"
    )
  );
};

export const flattenAndGroupIssues = (issues: IssueFieldsFragment[]) => {
  const obj: Record<string, Flattened<IssueFieldsFragment>[]> = {};
  let key = "";
  flattenIssues(issues).forEach((issue) => {
    if (issue.indent === 0) {
      const el = _.sortBy(issue.elements, "number")[0];
      key = el ? `${el.number} ${el.name}` : "Issues Without Elements";
      obj[key] = obj[key] || [];
    }
    obj[key].push(issue);
  });
  return obj;
};

export const flattenLocations = (locations: LocationFieldsFragment[]) => {
  if (!locations) return [];

  const processItems: Processor<LocationFieldsFragment> = (
    group,
    indent = 0
  ) => {
    return _.flatten(
      group.map((item, index) => [
        { ...item, indent, index },
        ...processItems(
          _.sortBy(
            locations.filter((l) => l.location?.id === item.id),
            "name"
          ),
          indent + 1
        )
      ])
    );
  };

  return processItems(
    _.sortBy(
      locations.filter((l) => !l.location),
      "name"
    )
  );
};
