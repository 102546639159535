import { useMemo } from "react";
import { useLoaderData } from "react-router";
import Badge from "~/components/badge";
import type {
  ComboBoxOption,
  ComboBoxParentPropsMultiple,
  ComboBoxParentPropsSingle
} from "~/components/combobox";
import ComboBox from "~/components/combobox";
import { useProject } from "~/contexts";
import type { IssuesPickerQuery } from "~/types/api";
import { flattenIssues } from "~/utils/bpa";
import DisplayNames from "~/utils/display-names";
import { plural } from "~/utils/formatting";
import IssueParser from "~/utils/issue-parser";
import { useFetcherData } from "~/utils/remix";

export type IssuePickerPropsSingle = ComboBoxParentPropsSingle & {
  keyPhotoCount?: boolean;
  untagged?: boolean;
  disableNoPhotos?: boolean;
  excludeId?: string;
};
export type IssuePickerPropsMulti = ComboBoxParentPropsMultiple & {
  keyPhotoCount?: boolean;
  untagged?: boolean;
  disableNoPhotos?: boolean;
  excludeId?: string;
};

export default function IssuePicker({
  keyPhotoCount = false,
  untagged,
  disableNoPhotos,
  excludeId,
  ...rest
}: IssuePickerPropsSingle | IssuePickerPropsMulti) {
  const project = useProject();
  const loader = useLoaderData<{ issuePicker?: IssuesPickerQuery } | null>();
  const fetcher = useFetcherData<IssuesPickerQuery>(
    loader?.issuePicker ? undefined : "/resources/issues/picker",
    { projectId: project.id }
  );

  const options = useMemo(() => {
    const sorted = IssueParser.from(
      loader?.issuePicker?.issues || fetcher.data?.issues || []
    ).sortedList();
    const flattened = flattenIssues(sorted).filter((i) => i.id !== excludeId);

    const options: ComboBoxOption[] = flattened.map((item) => {
      const count = item.pfcsPhotoCount + item.otherPhotoCount;
      const disabled = disableNoPhotos && count === 0;

      return {
        value: item.id,
        rightLabel:
          keyPhotoCount && item.pfcsKeyPhotoCount + item.otherKeyPhotoCount ? (
            <Badge
              mode="primary"
              label={plural(
                "Key Photo",
                item.pfcsKeyPhotoCount + item.otherKeyPhotoCount,
                true
              )}
            />
          ) : undefined,
        label: `${DisplayNames.issue(item)}${
          disabled && disableNoPhotos ? " (No Photos)" : ""
        }`,
        disabled: disabled,
        indentLevel: item.indent
      };
    });

    if (untagged) {
      options.push({
        value: "untagged",
        label: "*** Without Issues ***"
      });
    }
    return options;
  }, [
    disableNoPhotos,
    excludeId,
    fetcher.data,
    keyPhotoCount,
    untagged,
    loader?.issuePicker
  ]);

  return (
    <ComboBox placeholder="Select an Issue..." {...rest} options={options} />
  );
}
